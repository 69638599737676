<template>
  <modal name="error-report" class="modal-inner modal-error-report" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('error-report')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50">
      <span
        >{{ $t("error_report.error_reporting") }} <span v-if="report && user.role != 'tech'">- {{ report.unit_name }}</span></span
      >
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formCreateErrorReport" v-if="report != null">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.model" type="text" rules="required" :field_name="$t('error_report.serial_number')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.phone" type="text" rules="required" :field_name="$t('error_report.phone_number')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.imei_number" type="text" rules="required" :field_name="$t('error_report.IMEI')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div v-if="user.role != 'tech'" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.customer_name" type="text" rules="required" :field_name="$t('error_report.customer')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div v-if="user.role != 'tech'" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.unit_name" type="text" rules="required" :field_name="$t('error_report.department')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div v-if="user.role != 'tech'" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.contact_phone" type="text" rules="required" :field_name="$t('error_report.phone')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div v-if="user.role != 'tech'" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="report.contact_email" type="text" rules="required" :field_name="$t('error_report.email')" :readonly="notEditable" :disabled="notEditable" />
          </div>
          <div class="w-full px-2">
            <BaseTextarea rows="5" v-model="report.message" :field_name="$t('error_report.error_details')" rules="required" :readonly="notEditable" :disabled="notEditable" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer" v-if="!notEditable">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('error-report')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-blue px-3 py-2 float-right" @click="sendErrorReport()">{{ $t("error_report.send") }}<BaseIcon icon="arrow-right" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 700;

export default {
  props: {
    report: {
      required: true,
      default: null,
    },
    notEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
  computed: {
    alarmInfo() {
      return this.report;
    },
  },
  methods: {
    sendErrorReport() {
      this.$refs.formCreateErrorReport.validate().then((success) => {
        if (!success) {
          return;
        }

        let data = {
          phone: this.report.phone,
          model: this.report.model,
          imei: this.report.imei_number,
          customer_name: this.report.customer_name,
          unit_name: this.report.unit_name,
          contact_phone: this.report.contact_phone,
          email: this.report.contact_email,
          message: this.report.message,
        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/error-reports/`, data)
          .then(() => {
            // eslint-disable-line
            this.handleSuccess(this.$t("error_report.error_report_sent"));
            this.$refs.formCreateErrorReport.reset();
            this.hide("error-report");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    hide(id) {
      this.$refs.formCreateErrorReport.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
